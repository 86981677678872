/*
 * @Description: 
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-04-01 16:13:57
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-05-05 15:58:18
 */

import request from '@/request/request'
// 列表
export function all(data) {
    return request({
        url: '/Maintenance/Maintenance/List',
        method: 'post',
        data
    })
}
export function housesList(data) {
    return request({
        url: '/Maintenance/HousesSelectList',
        method: 'post',
        data
    })
}
// 详细数据
export function detail(data) {
    return request({
        url: '/Maintenance/Maintenance/Info',
        method: 'post',
        data
    })
}
// 接单
export function receiveOrder(data) {
    return request({
        url: '/Maintenance/Maintenance/ReceiveOrder',
        method: 'post',
        data
    })
}
// 处理工单
export function arriveOrder(data) {
    return request({
        url: '/Maintenance/Maintenance/ArriveOrder',
        method: 'post',
        data
    })
}
// 下拉筛选按钮  就是转单的时候把人员搞出来
export function staffSelectList(data) {
    return request({
        url: '/Maintenance/CompanyStaffSelectList',
        method: 'post',
        data
    })
}
// 转单
export function transferOrder(data) {
    return request({
        url: '/Maintenance/Maintenance/TransferOrder',
        method: 'post',
        data
    })
}
// 维保列表项
export function itemList(data) {
    return request({
        url: '/Maintenance/Maintenance/ItemList',
        method: 'post',
        data
    })
}
// 完结
export function successOrder(data) {
    return request({
        url: '/Maintenance/Maintenance/SuccessOrder',
        method: 'post',
        data
    })
}
